import feedbacksArr from "../data/feedbacksArr";
import createRandomColorViaHSL from "../utils/createRandomColor";

function feedbacks(commonWrapperSelector, btnAttrSelector, btnDisableClass) {
    const feedbacksCommonWrapper = document.querySelector(commonWrapperSelector),
          moreFeedbacksBtn = document.querySelector(btnAttrSelector);
   
    let start = 0,
        end = 3;
    let visibleFeedbacks = feedbacksArr.slice(start, end);
        
    function showVisibleFeedbacks() {
        visibleFeedbacks.forEach(feedback => {
            const feedbackWrapper = document.createElement('div');
            feedbackWrapper.classList.add('col-12', 'col-md-6', 'col-xl-4');
            feedbackWrapper.innerHTML = `
            <div class="comment">
            <div class="comment__wrapper1">
                <div 
                    class="comment__letter"
                    style="background-color: ${createRandomColorViaHSL()}">
                    ${feedback.name.slice(0, 1)}
                </div>
                <div class="comment_shell">
                    <div class="comment__name">${feedback.name}</div>
                    <div class="comment__date">${feedback.data}</div>
                </div>
            </div>
            <div class="comment__wrapper2">
                <img src="icons/feedback/stars.svg" alt="stars">
            </div>
            <div class="comment__wrapper3">${feedback.description}</div>
            </div>
            `;
            feedbacksCommonWrapper.append(feedbackWrapper);
        });
    }
    showVisibleFeedbacks();

    function loadMoreFeedbacks() {
        start = end;
        end += 6;
        visibleFeedbacks = feedbacksArr.slice(start, end);
        showVisibleFeedbacks();
        console.log(visibleFeedbacks);

        if (end >= feedbacksArr.length) {
            moreFeedbacksBtn.removeEventListener('click', loadMoreFeedbacks);
            moreFeedbacksBtn.classList.add(btnDisableClass);
            moreFeedbacksBtn.disabled = true;
        }
        moreFeedbacksBtn.blur();
    }

    moreFeedbacksBtn.addEventListener('click', loadMoreFeedbacks);

}

export default feedbacks;