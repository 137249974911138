function showVideo(videoWrapperSelector) {
    const videoWrappers = document.querySelectorAll(videoWrapperSelector);
        videoWrappers.forEach(videoWrapper => {
            const videoSrc = videoWrapper.getAttribute('data-src');
            videoWrapper.addEventListener('click', () => {
                videoWrapper.innerHTML = `<iframe src=${videoSrc} width="1280" height="720" allow="autoplay; encrypted-media; picture-in-picture; screen-wake-lock;" frameborder="0"></iframe>`;
            }, { once: true });
        })
}

export default showVideo;