import showVideo from './modules/video';
import tabs from './modules/tabs';
import feedbacks from './modules/feedbacks';
import modal from './modules/modal';
import forms from './modules/forms';
import burger from './modules/burger';
import smoothScroll from './modules/smooth-scroll';
import timer from './modules/timer';
import slider from './modules/slider';
import { openModal } from './modules/modal';

window.addEventListener('DOMContentLoaded', () => {
    const modalTimerId = setTimeout(() => {
        openModal('.modal', modalTimerId);
    }, 30000);


    tabs('.tabheader__item', '.tabcontent', '.tabheader__items', 'tabheader__item_active');
    feedbacks('#feedback-row', 'button[data-more-feedbacks]', 'button_disabled');
    modal('[data-modal]', '.modal', modalTimerId);
    forms('#form', modalTimerId);
    showVideo('.video__speechtherapist');
    burger({
        menuSelector: '.header__menu',
        menuItemSelector: '.header__item',
        hamburgerSelector: '.hamburger',
        menuSelectorActive: 'header__menu_active',
        hamburgerSelectorActive: 'hamburger_active'
    });
    slider('.carousel__inner');
    smoothScroll('.pageup', 1920);
    timer('.timer', '2024-09-06');
});
