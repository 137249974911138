import { openModal, closeModal } from "./modal";
import { sendMessageTelegram } from "../services/services";

function forms(formSelector, modalTimerId) {

    // Forms

    const form = document.querySelector(formSelector);
    const phoneInput = document.querySelector('input[name="phone"]'),
          nameInput = document.querySelector('input[name="name"]'),
          maxLengthName = 50,
          maxLengthPhone = 12;

    function validateForm(inp, pattern, maxLength) {
        inp.value = inp.value.replace(pattern, '');
        if (inp.value.length > maxLength) {
            inp.value = inp.value.slice(0, maxLength);
        }
    }

    nameInput.addEventListener('input', () => {
        validateForm(nameInput,/[^a-zA-Zа-яА-ЯёЁ\s]/g,maxLengthName);
        
    });



    phoneInput.addEventListener('input', () => {
        validateForm(phoneInput,/[^\d+]/g,maxLengthPhone);
    });


    let bot = {
        TOKEN: '6359534710:AAG3djMoTcE8fpCgXeM9QEVMkWivhyGMJy0',
        chatID: '-723546645'
    };

    const message = {
        loading: 'image/form/spinner.svg',
        success: 'Заявка успешно отправлена! Скоро мы с вами свяжемся',
        failure: 'Ошибка отправки! Воспользуйтесь мессенджером или СМС'
    };

    form.addEventListener('submit', (e) => {
        e.preventDefault();

        const statusMessage = document.createElement('img');
        statusMessage.src = message.loading;
        statusMessage.classList.add('show');
        form.append(statusMessage);



        const formData = new FormData(form),
            response = Object.fromEntries(formData.entries());



        sendMessageTelegram(`https://api.telegram.org/bot${bot.TOKEN}/sendMessage?chat_id=${bot.chatID}&text=name: ${response.name}; 
        phone: ${response.phone};`)
            .then((data) => {
                console.log(data);
                showThanksModal(message.success);
                statusMessage.remove();
            })
            .catch(() => {
                showThanksModal(message.failure);
            })
            .finally(() => {
                form.reset();
            });

            function showThanksModal(message) {
                const prevModalDialog = document.querySelector('.modal__dialog');

                prevModalDialog.classList.add('hide');
                openModal('.modal', modalTimerId);
    
                const thanksModal = document.createElement('div');
                thanksModal.classList.add('modal__dialog');
                thanksModal.innerHTML = `
            <div class="modal__content">
                <div data-close class="modal__close">&times;</div>
                <div class="modal__title">${message}</div>
            </div>
            `;
    
            document.querySelector('.modal').append(thanksModal);
            setTimeout(() => {
                thanksModal.remove();
                prevModalDialog.classList.remove('hide');
                // prevModalDialog.classList.add('show');
                closeModal('.modal');
            }, 5000);
            }
    });    
}

export default forms;