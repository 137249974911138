import { tns } from 'tiny-slider';

function slider(containerSelector) {

        //slider
    
        const slide = tns({
        container: containerSelector,
        preventScrollOnTouch: 'auto',
        items: 2,
        slideBy: 2,
        freezable: false,
        rewind: true,
        controls: false,
        nav: false,
        mouseDrag: true,
        responsive: {
            320: {
                slideBy: 1,
                items: 1,
            },
            640: {
                slideBy: 1,
                items: 1,
                edgePadding: 20,
                gutter: 20
            },
            700: {
                items: 1,
                gutter: 30
            },
            900: {
                items: 2
            }
        }
    });
    
    document.querySelector('.prev').addEventListener('click', function () {
        slide.goTo('prev');
    });

    document.querySelector('.next').addEventListener('click', function () {
        slide.goTo('next');
    });
}

export default slider;