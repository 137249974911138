const feedbacksArr = [
    {
        name: 'Сергей',
        data: '21 апреля',
        description: 'Работали с Вероникой несколько месяцев, исправляли звук Р. Сразу нашли контакт и выстроили доверительные отношения. С другими логопедами, коих было множество, заниматься было некомфортно, но с Вероникой 15 уроков пролетели незаметно, мы успели не только исправить горловое звучание, но и закрепить этот навык в бытовой речи. Очень признателен за проделанную работу, терпение и доброту!'
    },
    {
        name: 'Артём',
        data: '13 июня',
        description: 'Огромное спасибо Веронике! занятия интересные ,интерактивные ,веселые ! дочке очень понравились логопедические игры и это ее первый опыт работы за ноутбуком в неполных три года ! Рекомендую!'
    },
    {
        name: 'Валентина',
        data: '10 мая',
        description: 'После инсульта муж столкнулся с нарушением речи. Нам посоветовали обратиться к афазиологу Веронике друзья, ко. Процесс не был легким и требовал времени, но постепенно мы заметили улучшения. Через 1,5 года муж уже может свободно разговаривать и читать. На данный момент все аспекты его коммуникации в порядке, и мы очень счастливы результатом.'
    },
    {
        name: 'Наталья',
        data: '18 июля',
        description: 'Замечательный логопед ,знаток своего дела, легко находит общий язык с ребёнком, всё по делу.Советую не пожалеете!'
    },
    {
        name: 'Елена',
        data: '7 августа',
        description: 'Замечательный преподаватель, ребенок занимается с удовольствием, занятия происходят в игровой форме, видны изменения в речи у дочери! Занимаемся онлайн!'
    },
    {
        name: 'Анна',
        data: '15 сентября',
        description: 'Сын занимается с Вероникой онлайн около месяца, результат уже есть) очень нравится формат занятий, задания разнообразные, что не дает ребенку скучать.'
    },
    {
        name: 'Катя',
        data: '17 сентября',
        description: 'Все только положительные эмоции от сотрудничества с Вероникой. Доброжелательное, легкое общение с ребенком, поставили звуки без особых проблем.'
    },
    {
        name: 'Лариса',
        data: '13 ноября',
        description: 'Очень грамотный преподаватель, поставила звук «р» на занятиях онлайн ребенку. Дополнительно были темы на развитие мышления. Помимо этого, хотелось бы отметить вежливость, порядочность и доброжелательность в адрес ребёнка.'
    },
    {
        name: 'Алла',
        data: '25 декабря',
        description: 'Вероника отличный педагог! Нашла подход к сыну с первых минут. И результат очевиден! Стал читать вывески, указатели. Проявляет интерес!'
    },
    {
        name: 'Наталья',
        data: '22 января',
        description: 'Очень хороший логопед, который нашел контакт с ребенком с первых минут. Каждое занятие было в радость. Очень помогли, спасибо большое! Рекомендую!'
    },
    {
        name: 'Юлия',
        data: '21 марта',
        description: 'Обращались к Веронике, дочь пропускала при писании буквы в словах. Я в расстроенных чувствах что это дисграфия пришла за помощью к Веронике. Вероника успокоила что диагнозов никаких у нас нет и достаточно заниматься на анализ звуков. Дочь с большим удовольствием занималась. Вероника умеет расположить к себе ребенка и заинтересовать в занятии. Результаты не заставили себя долго ждать! Стала намного чище тетрадь! Спасибо большое!!!'
    },
    {
        name: 'Анна',
        data: '28 марта',
        description: 'Спасибо. Прекрасный логопед!'
    },
    {
        name: 'Ольга',
        data: '8 апреля',
        description: 'Работой со специалистом очень довольна. Понравилось всё: стоимость, результаты занятий, отношение. Рекомендую!'
    },
    {
        name: 'YanaK',
        data: '21 августа',
        description: 'Идеальный педагог для моей придирчивой дочери, которая каждую   субботу бежит заниматься с радостью. За 4 месяца занятий раз в неделю у дочери стали все звуки и она стала красиво и четко говорить. Услада для ушей. Спасибо Веронике за прекрасную работу и тысячу полезных советов!'
    },
    {
        name: 'Елизавета Олеговна',
        data: '20 апреля',
        description: 'Вероника супер логопед! Уже через 8 занятий дочка разговорилась, стала произносить слова четко и практически уже не путается в звуках. Результат заметили все родственники, преподаватели в саду и все наше окружение !!! Я в полном восторге, Надя ждет уроки с нетерпением, ей очень нравится. Спасибо за вашу работу и заботу !!!'
    },
    {
        name: 'Сергей',
        data: '21 января',
        description: 'Работали с Вероникой несколько месяцев, исправляли звук Р. Сразу нашли контакт и выстроили доверительные отношения. С другими логопедами, коих было множество, заниматься было некомфортно, но с Вероникой 15 уроков пролетели незаметно, мы успели не только исправить горловое звучание, но и закрепить этот навык в бытовой речи. Очень признателен за проделанную работу, терпение и доброту!'
    },
    {
        name: 'Polina Bekker',
        data: '12 марта',
        description: 'Вероника, спасибо большое Вам за профессионализм! Очень приятный,  открытый, эмпатичный преподаватель, знающий своё дело и умеющий найти подход к ребёнку! За 5 занятий мы поставили звук «Р», что в условиях он-лайн занятий, довольно не просто! Спасибо Вам большое!'
    },
];

export default feedbacksArr;