function burger({menuSelector, menuItemSelector, hamburgerSelector, menuSelectorActive, hamburgerSelectorActive}) {
        
    //burger

    const menu = document.querySelector(menuSelector),
          menuItem = document.querySelectorAll(menuItemSelector),
          hamburger = document.querySelector(hamburgerSelector);

    hamburger.addEventListener('click', () => {
        hamburger.classList.toggle(hamburgerSelectorActive);
        menu.classList.toggle(menuSelectorActive);
    });

    menuItem.forEach(item => {
        item.addEventListener('click', () => {
            hamburger.classList.toggle(hamburgerSelectorActive);
            menu.classList.toggle(menuSelectorActive);
        });
    });
}

export default burger;